.sidebar .nav-link:hover {
  color: #fff;
  background: #162C56 !important;
}

.sidebar {
  background-color: #112752 !important; ;
}

.sidebar .nav-link.active {
  color: #fff;
  background:#586886 !important;
}
.sidebar .nav-link.active .nav-icon {
  color: #fff !important;
}

.test_style {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 0;
  overflow-y: auto;
  background-color: rgba(0,0,0,.4);
  z-index: 10000;
  pointer-events: none;
  transition: opacity .3s;
}

.test_h1{
  z-index: 1000000;
  /* margin: 100px; */
  padding: 20%;
  /*background: red;*/
}

.test_img {
  width: 60px;

  /* for animation*/
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6  {
  font-family: 'Nunito', sans-serif;
  color: #5f5f5f;
}
a , li , form{
  font-family: 'Nunito', sans-serif;
}

tr , th , div , button ,small{
  font-family: 'Nunito', sans-serif;
}

.ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text{
  color:#5c6873!important;
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.main{
  background-color: white !important;
}

#profile-picture{
  width: auto;
  height: 31px;
  border-radius: 50%;
}

#headerIcon{
  width: 18px;
  height: 18px;
  margin-right: 18px;
}

.header-portal-name{
  margin-right: 27rem !important;
}

@media screen and (min-width: 1920px) {
  .header-portal-name{
    margin-right: 50rem !important;
  }
}
